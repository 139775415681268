import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Payslip } from '@ciphr/domains/payslips/models';
import { Ordering } from '@ciphr/utils/collections';

export const payslipsFeatureActions = createActionGroup({
  source: 'Payslips Feature',
  events: {
    'Change Payslips List Date Range': props<{ from: string; to: string }>(),
    'Change Payslips List Page': props<{ pageIndex: number; pageSize: number }>(),
    'Load Current Payslip': emptyProps(),
    'Clear Current Payslip': emptyProps(),
    'Load Payslip': props<{ id: string }>(),
    'Load Payslips List': emptyProps(),
    'Clear Payslips List': emptyProps(),
    'Reset Active Payslip': emptyProps(),
    'Reset Payslips List Paging': emptyProps(),
    'Sort Payslips List': props<{ orderBy: Ordering }>(),
    'Download Payslip': props<{ id: Payslip['id'] }>(),
    'Download All Payslips': emptyProps(),
  },
});
